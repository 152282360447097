import React from "react";
import ImageOne from "../../images/WelcomeGreenYellow.png";
import ImageTwo from "../../images/moi.jpg";
import Unauthorized from "../../images/unauthorized.jpg";

export const InfoData = {
  heading: "Salut à toi",
  paragraphOne:
    "Prêt à faire un pas vers une version plus forte, plus saine et plus épanouie de toi-même ? Avec moi, c'est bien plus qu'un simple coaching sportif et nutritionnel. C'est une expérience personnalisée où le sport et la nutrition se marient pour créer ton propre chemin vers le bien-être.",
  paragraphTwo:
    "Si tu cherches un compagnon de route qui sera à tes côtés à chaque étape, tu es au bon endroit. Je m'appelle Alexia, et je suis là pour te guider, t'inspirer et te soutenir dans ta quête de transformation. Découvre comment ensemble, nous allons donner vie à tes objectifs et faire de chaque étape de ton parcours une aventure personnelle passionnante.",
  buttonLabel: "Plus d'informations",
  link: "/coach",
  image: ImageOne,
  reverse: false,
  delay: 100,
};

export const InfoDataTwo = {
  heading: "Moi-même",
  paragraphOne: "Je m'appelle Alexia...",
  paragraphTwo:
    "eleifend malesuada neque. Suspendisse non est at erat commodo malesuada. Phasellus sit amet nibh vitae risus dictum placerat. Donec molestie lectus gravida imperdiet blandit. Suspendisse pretium ultrices massa at suscipit. Nullam aliquam varius mi, ac vulputate metus pharetra vel. Proin maximus sollicitudin odio, in finibus velit hendrerit non. Duis non hendrerit nisl. Donec faucibus tincidunt dictum. Etiam vel massa dui.",
  buttonLabel: "View Designs",
  link: "/",
  image: ImageTwo,
  reverse: false,
  delay: 300,
};

export const UnauthorizedData = {
  heading: "Unauthorized",
  paragraphOne: "You do have access to the requested data",
  paragraphTwo: "",
  buttonLabel: "Go back to home screen",
  link: "/",
  image: Unauthorized,
  reverse: false,
  delay: 300,
};
