import React from "react";
import WeeklyCheckInForm from "../components/clientCoachUpdates/clientSection/weeklyCheckInForm/WeeklyCheckInForm";
import Header from "../components/Header";

import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const WeeklyCheckIn = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="Check-in" subtitle="A compléter chaque semaine" />
        </Box>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: "2rem" }}>
            <Typography
              variant="body1"
              color={colors.grey[100]}
              align="left"
              display="block"
            >
              Ce bilan, à faire à la fin de chaque semaine, me permet de suivre
              ton évolution. <br /> En fonction de tes résultats, je vais
              pouvoir adapter au mieux ton programme. <br /> Rappel toi que tu
              fais ça pour toi, et que pour toi. <br /> Ne triche pas, il n'y
              aurait pas d'intérêt. Je suis là pour t'aider à atteindre au mieux
              tes objectifs.
            </Typography>
          </Paper>
        </Grid>

        <Box m={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <WeeklyCheckInForm />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Section>
  );
};

export default WeeklyCheckIn;
