import React from "react";
import LineChart from "../../../LineChart"; // Adjust the import based on your file structure
import { tokens } from "../../../../../theme";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const ClientMensurationChart = ({ dataUsed, isDashboard }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box backgroundColor={colors.primary[400]}>
      {" "}
      {/* You can adjust the background color or other styles as needed */}
      <Box
        mt="25px"
        p="0 30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="h5"
            fontWeight="600"
            color={colors.grey[100]} // Adjust the color based on your theme
          >
            Weight
          </Typography>
          <Typography
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[500]} // Adjust the color based on your theme
          >
            KG
          </Typography>
        </Box>
        <Box>
          <IconButton>
            <DownloadOutlinedIcon
              sx={{
                fontSize: "26px",
                color: "#0f0", // Adjust the color based on your theme
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box height="500px" m="-20px 0 0 0">
        <LineChart dataUsed={dataUsed} isDashboard={isDashboard} />
      </Box>
    </Box>
  );
};

export default ClientMensurationChart;
