import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../../../theme";
import ClientMensurationChart from "./clientMensurationChart/ClientMensurationChart";
import ClientMensurationStatBoxWrapper from "./clientMensurationStatBoxWrapper/ClientMensurationStatBoxWrapper";
import {
  calculateDifferences,
  prepareChartData,
} from "./dataProcessing/DataProcessing";
import apiService from "../../../../ApiService";

const ClientMensurationDataDashboard = ({
  clientID,
  apiEndpoints,
  user,
  calculateDifference,
  formatTimestamp,
  showStatBoxes = true,
}) => {
  const theme = useTheme();

  const bodyPartColors = [
    "hsl(218, 100%, 63%)",
    "hsl(212, 80%, 42%)",
    "hsl(174, 100%, 29%)",
    "hsl(88, 50%, 53%)",
    "hsl(45, 100%, 51%)",
    "hsl(36, 100%, 50%)",
    "hsl(4, 90%, 58%)",
    "hsl(334, 79%, 38%)",
  ];

  const [clientData, setClientData] = useState(null);
  const [mensurations, setMensurations] = useState([]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await apiService.get(
          `${apiEndpoints.CLIENTDASHBOARD}/${clientID}`
        );
        const clientData = response.data;
        setClientData(clientData);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientID) {
      fetchClientData();
    }
  }, [clientID]);

  useEffect(() => {
    const fetchMensurationsData = async () => {
      try {
        if (clientData && user.id) {
          const response = await apiService.get(
            apiEndpoints.CLIENT_MENSURATIONS(clientID)
          );
          const mensurationsData = response.data;

          setMensurations(mensurationsData);
        }
      } catch (error) {
        console.error("Error fetching mensurations:", error);
      }
    };

    fetchMensurationsData();
  }, [clientData, user.id]);

  const differences = calculateDifferences(mensurations, calculateDifference);
  const mensurationsChartData = prepareChartData(
    mensurations,
    formatTimestamp,
    bodyPartColors
  );

  if (!clientData) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {showStatBoxes &&
          Object.keys(differences).map((key) => (
            <Grid key={key} xs={12} sm={12} md={6} lg={1.5} xl={1.5}>
              <ClientMensurationStatBoxWrapper
                difference={differences[key]}
                subtitle={key}
              />
            </Grid>
          ))}
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12}>
            <ClientMensurationChart
              dataUsed={mensurationsChartData}
              isDashboard={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientMensurationDataDashboard;
