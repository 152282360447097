import axios from "axios";

// Debug log to ensure environment variable is read
console.log("Environment Variable:", process.env.REACT_APP_API_URL);

//const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";
const API_BASE_URL = "http://localhost:8080";

//const API_BASE_URL = process.env.REACT_APP_API_URL;

console.log("API Base URL:", API_BASE_URL);

const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 50000000000, // You can adjust the timeout
});

// Set the authorization token in headers
export const setAuthToken = (token) => {
  if (token) {
    apiService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete apiService.defaults.headers.common["Authorization"];
  }
};

export const API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}/api/auth/signin`,

  CLIENTS: `${API_BASE_URL}/api/clients/all`, // Replace with your actual client endpoint
  CLIENTDASHBOARD: `${API_BASE_URL}/api/clients`,
  //NEW_CLIENT: `${API_BASE_URL}/api/clients/create`,
  NEW_CLIENT: `${API_BASE_URL}/api/auth/signup`,

  CLIENT_MENSURATIONS: (clientId) =>
    `${API_BASE_URL}/api/${clientId}/mensurations`,

  CLIENT_POIDS: (clientId) => `${API_BASE_URL}/api/${clientId}/poids`,
  CLIENT_ADD_POIDS: `${API_BASE_URL}/api/add/poids`,

  CLIENT_SLEEP: (clientId) => `${API_BASE_URL}/api/${clientId}/sleep`,
  CLIENT_ADD_SLEEP: `${API_BASE_URL}/api/add/client/sleep`,

  CLIENT_ADD_MENSURATIONS: `${API_BASE_URL}/api/add/mensuration`,

  CLIENT_NUTRITION: (clientId) => `${API_BASE_URL}/api/${clientId}/nutrition`,
  COACH_UPDATE_CLIENT_INFORMATION_NUTRITON_PER_WEEK: (clientId) =>
    `${API_BASE_URL}/api/${clientId}/updateinformationperweek`,
  //We add new data if the user have none
  ADD_CLIENT_INFORMATION_NUTRITION: `${API_BASE_URL}/api/add/nutritioninformation`,
  // COACH_ADD_CLIENT_INFORMATION_NUTRITON_PER_WEEK: (clientId) => `${API_BASE_URL}/api/${clientId}/add/nutritioninformation`,

  // Add more endpoints as needed

  CONTACT: `${API_BASE_URL}/api/apply`,

  PASSWORD_CHANGE: `${API_BASE_URL}/api/auth/change-password`,

  CLIENT_ADD_NUTRITION_PICTURES: `${API_BASE_URL}/api/add/nutrition-pictures`,
  CLIENT_RETRIEVE_NUTRITION_PICTURES: (clientId) =>
    `${API_BASE_URL}/api/${clientId}/nutrition-pictures`,
  CLIENT_ADD_BODY_PICTURES: `${API_BASE_URL}/api/add/body-pictures`,
  CLIENT_RETRIEVE_BODY_PICTURES: (clientId) =>
    `${API_BASE_URL}/api/${clientId}/body-pictures`,
};

// Add more functions for other API endpoints as needed
// ...

export default apiService;
