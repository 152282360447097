import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import apiService from "../../ApiService";
import { API_ENDPOINTS } from "../../ApiService";
import { setAuthToken } from "../../ApiService";
import {
  FormContainer,
  FormTitle,
  FormPaper,
  FormTextField,
  FormButton,
} from "../components/FormStyles";

const ChangePassword = () => {
  const { clientID } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("Token found:", token);
      setAuthToken(token); // Set the token in the headers
    } else {
      console.log("No token found");
      setMessage("Authentication token is missing. Please log in again.");
    }
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post(
        API_ENDPOINTS.PASSWORD_CHANGE,
        formData
      );

      if (response.status === 200) {
        setMessage("Password changed successfully!");
        setTimeout(() => {
          navigate(`/dashboard/${clientID}`);
        }, 2000);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setMessage("Error changing password");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <FormContainer container justifyContent="center">
      <FormPaper>
        <form onSubmit={handleFormSubmit}>
          <FormTitle variant="h4" gutterBottom>
            Change Password
          </FormTitle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                label="Old Password"
                name="oldPassword"
                type="password"
                value={formData.oldPassword}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="New Password"
                name="newPassword"
                type="password"
                value={formData.newPassword}
                onChange={handleInputChange}
                required
              />
            </Grid>
          </Grid>
          <FormButton type="submit" variant="contained" color="primary">
            Change Password
          </FormButton>
          {message && <p>{message}</p>}
        </form>
      </FormPaper>
    </FormContainer>
  );
};

export default ChangePassword;
