import React from "react";
import { Typography, Box } from "@mui/material";
import { grey } from "@mui/material/colors";

const primary = grey[300]; // #f44336

const BoxModified = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
        boxShadow: 1,
        padding: "1rem",
        borderRadius: "16px",
        backgroundColor: primary,
      }}
    >
      <Typography variant="h5">{props.content}</Typography>
    </Box>
  );
};

export default BoxModified;
