import React from "react";
import { Route, Navigate } from "react-router-dom";

import { isAuthenticated } from "./auth";
import { useUser } from "../pages/authentification/UserContext";

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { user } = useUser();
  const { role } = user;
  console.log("Role", role);

  if (!role || !allowedRoles.includes(role)) {
    return <Navigate to="/" />;
  }

  //   return <Route {...rest} render={(props) => <Component {...props} />} />;
  return <Component />;
};
export default PrivateRoute;
