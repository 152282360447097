import styled from 'styled-components';
import { Grid, Paper, Typography, TextField, Button } from '@mui/material';

export const FormContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const FormTitle = styled(Typography)`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const PageTitle = styled(Typography)`
  && {font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;}
`;

export const FormPaper = styled(Paper)`
  padding: 20px;
  text-align: center;
  background-color: #f3f3f3; /* Grey background color */
  width: 33%; /* Take 1/3 of the page width */ 
`;

export const FormTextField = styled(TextField)`
  margin-bottom: 15px;
  size='small';
`;

export const FormButton = styled(Button)`
  margin-top: 10px;
`;