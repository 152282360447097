import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../../../ApiService";
import { useUser } from "../../../pages/authentification/UserContext";
import { API_ENDPOINTS, API_BASE_URL } from "../../../../ApiService";
import { setAuthToken } from "../../../../ApiService";
import styled from "styled-components";
import { Button } from "@mui/material";

import {
  Avatar,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import HttpsIcon from "@mui/icons-material/Https";

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: auto;
  background-color: #ffffff; /* Light background */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); /* Soft shadow */
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LoginForm = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [formData, setFormData] = useState({ username: "", password: "" });

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Login", formData);

    try {
      const response = await apiService.post(API_ENDPOINTS.LOGIN, formData);
      if (response.status === 200) {
        console.log("Login worked");
        console.log(response);

        const { accessToken, roles, id } = response.data;
        setUser({ token: accessToken, role: roles[0], id: id });

        setAuthToken(accessToken);
        localStorage.setItem("authToken", accessToken);

        if (roles[0] === "ROLE_ADMIN") {
          navigate("/clients");
        } else {
          navigate(`/dashboard/${id}`);
        }
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <Section>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <StyledPaper elevation={6}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <HttpsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
      </Container>
      <Box
        component="div"
        sx={{
          display: { xs: "none", md: "block" },
          width: "50%",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Image
          src="https://images.unsplash.com/photo-1531746790731-6c087fecd65a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDF8fGxvZ2luJTIwcGF0dGVybnxlbnwwfHx8fDE2MjYyNzk2MjI&ixlib=rb-1.2.1&q=80&w=1080"
          alt="Login Background"
        />
      </Box>
    </Section>
  );
};

export default LoginForm;
