import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Typography, Box, Button, Container } from "@mui/material";
import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Highlight = styled.span`
  background-color: #fcdc00;
`;

const Logout = ({ onLogout }) => {
  useEffect(() => {
    AOS.init();
    onLogout(); // Call the logout function when the component mounts
  }, [onLogout]);

  return (
    <Section>
      <Typography variant="h2" align="center" gutterBottom>
        Vous êtes bien déconnectés!
      </Typography>
      <Box textAlign="center" marginBottom="4rem">
        <Typography variant="body1">A très bientôt</Typography>
      </Box>
      <Button variant="contained" color="primary" href="/login">
        Se connecter
      </Button>
    </Section>
  );
};

export default Logout;
