import { User } from "lucide-react";
import { Link } from "react-router-dom";
import { useUser } from "../../pages/authentification/UserContext";

const UserProfile = ({ token }) => {
  const { user } = useUser();
  const { id } = user;
  return (
    <div className="register">
      <div className="block md:hidden">
        <button className="avatar">
          {token ? (
            <a href="/logout">
              <User />
            </a>
          ) : (
            <a href="/login">
              <User />
            </a>
          )}
        </button>
      </div>
      <div className="hidden md:block">
        {token ? (
          <div>
            <button className="sign">
              <Link to="/logout">Logout</Link>
            </button>
            <button className="sign">
              <Link to={`/changepassword/${id}`}>Changer le mot de passe</Link>
            </button>
          </div>
        ) : (
          <button className="sign">
            <Link to="/login">Accès membre</Link>
          </button>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
