import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Grid } from "@mui/material";
import apiService from "../../../../../ApiService";
import { API_ENDPOINTS } from "../../../../../ApiService";
import { FormTextField, FormButton } from "../../../FormStyles";

const ClientRegistrationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    username: "",
    email: "",
    password: "",
    datedenaissance: "",
    age: "",
    poidsdorigine: "",
    poidsactuel: "",
    taille: "",
    activitylevel: "",
    nbjourdeworkout: "",
    coach: "",
  });

  const [confirmationMessage, setConfirmationMessage] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        nom: formData.nom,
        prenom: formData.prenom,
        username: formData.username,
        email: formData.email,
        password: formData.password,
        datedenaissance: formData.datedenaissance,
        age: parseInt(formData.age),
        poidsdorigine: parseFloat(formData.poidsdorigine),
        poidsactuel: parseFloat(formData.poidsactuel),
        taille: parseInt(formData.taille),
        activitylevel: parseInt(formData.activitylevel),
        nbjourdeworkout: parseInt(formData.nbjourdeworkout),
        coach: parseInt(formData.coach),
        role: [1],
      };

      const response = await apiService.post(API_ENDPOINTS.NEW_CLIENT, payload);

      if (response.status === 201) {
        console.log(payload);
        // Show confirmation message
        setConfirmationMessage("User registered successfully!");
        // Redirect to client dashboard after a delay
        setTimeout(() => {
          navigate(`/dashboard`);
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <form onSubmit={handleFormSubmit}>
        {/* <FormTitle variant="h4" gutterBottom>
            Client Registration
          </FormTitle> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormTextField
              label="First Name"
              name="prenom"
              value={formData.prenom}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Last Name"
              name="nom"
              value={formData.nom}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              // label="Date of Birth"
              name="datedenaissance"
              type="date"
              value={formData.datedenaissance}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Age"
              name="age"
              type="number"
              value={formData.age}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Starting Weight (kg)"
              name="poidsdorigine"
              type="number"
              value={formData.poidsdorigine}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Current Weight (kg)"
              name="poidsactuel"
              type="number"
              value={formData.poidsactuel}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Height (cm)"
              name="taille"
              type="number"
              value={formData.taille}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Activity Level"
              name="activitylevel"
              type="number"
              value={formData.activitylevel}
              onChange={handleInputChange}
              required
            />
          </Grid>
          {/* <FormControl fullWidth>
                <InputLabel>Activity Level</InputLabel>
                <Select
                  name="activitylevel"
                  value={formData.activitylevel}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value={1}>Sedentary</MenuItem>
                  <MenuItem value={2}>Lightly Active</MenuItem>
                  <MenuItem value={3}>Moderately Active</MenuItem>
                  <MenuItem value={4}>Very Active</MenuItem>
                  <MenuItem value={5}>Super Active</MenuItem>
                </Select>
              </FormControl> */}
          <Grid item xs={6}>
            <FormTextField
              label="Number of Workout Days per Week"
              name="nbjourdeworkout"
              type="number"
              value={formData.nbjourdeworkout}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              label="Coach ID"
              name="coach"
              type="number"
              value={formData.coach}
              onChange={handleInputChange}
              required
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <FormButton type="submit" variant="contained" color="primary">
            Register
          </FormButton>
        </Grid>
      </form>
      {confirmationMessage && (
        <Typography
          variant="h6"
          color="success.main"
          style={{ marginTop: "20px" }}
        >
          {confirmationMessage}
        </Typography>
      )}
    </Paper>
  );
};

export default ClientRegistrationForm;
