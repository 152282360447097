import React from "react";
import { useParams } from "react-router-dom";
import { useUser } from "./authentification/UserContext";
import { API_ENDPOINTS } from "../../ApiService";
import ClientMensurationDataDashboard from "../components/clientDashboard/mensuration/ClientMensurationDataDashboard";
import {
  calculateDifferences,
  formatTimestamp,
} from "../components/clientDashboard/mensuration/dataProcessing/DataProcessing";
import Header from "../components/Header";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const MensurationsDashboard = () => {
  const { clientID } = useParams();
  const { user } = useUser();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const calculateDifference = (latest, previous) => latest - previous;

  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header
            title="Mensuration Dashboard"
            subtitle="Mensuration tracking through the weeks"
          />
        </Box>
        <ClientMensurationDataDashboard
          clientID={clientID}
          apiEndpoints={API_ENDPOINTS}
          user={user}
          calculateDifference={calculateDifference}
          formatTimestamp={formatTimestamp}
          showStatBoxes={true}
        />
      </Box>
    </Section>
  );
};

export default MensurationsDashboard;
