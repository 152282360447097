import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Divider,
  useTheme,
} from "@mui/material";
import BoxModified from "../../../box/BoxModified";
import apiService from "../../../../../ApiService";
import { API_ENDPOINTS } from "../../../../../ApiService";
import { tokens } from "../../../../../theme";

// Helper function to capitalize the first letter of each day
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const parseDurationInput = (input) => {
  // Assuming the input is in the form "XhY" or "Xh Ym", where X is hours and Y is minutes
  const match = input.match(/(\d+)h\s*(\d*)m?/);
  if (match) {
    const hours = match[1];
    const minutes = match[2] || "0"; // Default minutes to '0' if not specified
    return `${hours}h ${minutes}m`;
  }
  return null; // Return null if input does not match expected format
};

const WeeklyCheckInForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const greenColor = "#5499C7"; // Default to green if undefined
  const { clientID } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: "",
    taille: "",
    fesses: "",
    bicepsdroit: "",
    bicepsgauche: "",
    cuissedroite: "",
    cuissegauche: "",
    molletdroit: "",
    molletgauche: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });

  const [nutritionImages, setNutritionImages] = useState({
    nutrients: null,
    calories: null,
  });
  const [bodyImages, setBodyImages] = useState({
    front: null,
    back: null,
    side: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e, type) => {
    const { name, files } = e.target;
    if (type === "nutrition") {
      setNutritionImages((prevImages) => ({
        ...prevImages,
        [name]: files[0],
      }));
    } else if (type === "body") {
      setBodyImages((prevImages) => ({
        ...prevImages,
        [name]: files[0],
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("date", formData.date);
    formData.append("clientid", clientID);
    formData.append("nutrients", nutritionImages.nutrients);
    formData.append("calories", nutritionImages.calories);

    formData.append("front", bodyImages.front);
    formData.append("back", bodyImages.back);
    formData.append("side", bodyImages.side);

    const mensurationPayload = {
      datemensuration: formData.date,
      taille: parseFloat(formData.taille),
      fesses: parseFloat(formData.fesses),
      bicepsdroit: parseFloat(formData.bicepsdroit),
      bicepsgauche: parseFloat(formData.bicepsgauche),
      cuissedroite: parseFloat(formData.cuissedroite),
      cuissegauche: parseFloat(formData.cuissegauche),
      molletdroit: parseFloat(formData.molletdroit),
      molletgauche: parseFloat(formData.molletgauche),
      clientid: parseInt(clientID),
    };

    const sleepPayload = {
      sleepweeklydate: formData.date,
      monday: parseDurationInput(formData.monday),
      tuesday: parseDurationInput(formData.tuesday),
      wednesday: parseDurationInput(formData.wednesday),
      thursday: parseDurationInput(formData.thursday),
      friday: parseDurationInput(formData.friday),
      saturday: parseDurationInput(formData.saturday),
      sunday: parseDurationInput(formData.sunday),
      clientid: parseInt(clientID),
    };

    try {
      const mensurationResponse = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_MENSURATIONS,
        mensurationPayload
      );
      const sleepResponse = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_SLEEP,
        sleepPayload
      );
      const nutritionResponse = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_NUTRITION_PICTURES,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const bodyResponse = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_BODY_PICTURES,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        mensurationResponse.status === 201 &&
        sleepResponse.status === 201 &&
        nutritionResponse.status === 201 &&
        bodyResponse.status === 201
      ) {
        console.log(mensurationPayload);
        console.log(sleepPayload);
        navigate(`/dashboard/${clientID}`);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      {/* <Typography variant="h4" gutterBottom>
        Update Mensurations and Sleep Data
      </Typography> */}
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // label="Date of Record"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleInputChange}
              required
              fullWidth
            />
          </Grid>
          <BoxModified content={"Mensurations"} />
          <Box
            sx={{
              width: "100%",
              mt: 2,
              padding: "1rem",
              borderRadius: "16px",
            }}
          >
            <Typography
              variant="body1"
              color={greenColor}
              align="left"
              display="block"
            >
              Exemple: 64
            </Typography>
          </Box>
          {/* Example of creating form fields in a loop */}
          {[
            { name: "taille", label: "Taille" },
            { name: "fesses", label: "Fesses" },
            { name: "bicepsdroit", label: "Biceps Droit" },
            { name: "bicepsgauche", label: "Biceps Gauche" },
            { name: "cuissedroite", label: "Cuisse Droite" },
            { name: "cuissegauche", label: "Cuisse Gauche" },
            { name: "molletdroit", label: "Mollet Droit" },
            { name: "molletgauche", label: "Mollet Gauche" },
          ].map((input) => (
            <Grid item xs={12} sm={6} key={input.name}>
              <TextField
                label={input.label}
                name={input.name}
                type="number"
                value={formData[input.name]}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
          ))}
          <Divider style={{ margin: "20px 0", width: "100%" }} />
          <BoxModified content={"Sommeil"} />
          <Box
            sx={{
              width: "100%",
              mt: 2,
              padding: "1rem",
              borderRadius: "16px",
            }}
          >
            <Typography
              variant="body1"
              color={greenColor}
              align="left"
              display="block"
            >
              Exemple: 7h54
            </Typography>
          </Box>
          {[
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
          ].map((day) => (
            <Grid item xs={12} sm={6} key={day}>
              <TextField
                label={capitalizeFirstLetter(day)}
                name={day}
                type="text"
                value={formData[day]}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
          ))}
          <Divider style={{ margin: "20px 0", width: "100%" }} />
          <BoxModified content={"Nutrition Pictures"} />
          <Grid item xs={12} sm={6}>
            <input
              type="file"
              accept="image/*"
              name="nutrients"
              onChange={(e) => handleImageChange(e, "nutrition")}
              required
            />
            <Typography variant="body2">Nutrients Picture</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="file"
              accept="image/*"
              name="calories"
              onChange={(e) => handleImageChange(e, "nutrition")}
              required
            />
            <Typography variant="body2">Calories Picture</Typography>
          </Grid>
          <Divider style={{ margin: "20px 0", width: "100%" }} />
          <BoxModified content={"Body Pictures"} />
          <Grid item xs={12} sm={4}>
            <input
              type="file"
              accept="image/*"
              name="front"
              onChange={(e) => handleImageChange(e, "body")}
              required
            />
            <Typography variant="body2">Front Picture</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <input
              type="file"
              accept="image/*"
              name="back"
              onChange={(e) => handleImageChange(e, "body")}
              required
            />
            <Typography variant="body2">Back Picture</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <input
              type="file"
              accept="image/*"
              name="side"
              onChange={(e) => handleImageChange(e, "body")}
              required
            />
            <Typography variant="body2">Side Picture</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit Data
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default WeeklyCheckInForm;
