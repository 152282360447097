export const menuData = [
  {
    label: "Types de coaching",
    href: "/coachingtypes",
    children: [
      {
        heading: "Nutrition",
        submenu: [
          { label: "Le coaching", href: "#" },
          {
            label: "L'importance de la nutrition",
            href: "#",
          },
        ],
      },
      {
        heading: "Musculation",
        submenu: [
          { label: "Le coaching", href: "#" },
          {
            label: "L'importance de la musculation",
            href: "#",
          },
          { label: "Ce que vous allez apprendre", href: "#" },
        ],
      },
      {
        heading: "Nutrition + Musculation",
        submenu: [{ label: "Le coaching", href: "#" }],
      },
    ],
  },
  {
    label: "Coach",
    href: "/coach",
  },
  { label: "Ebooks", href: "#" },
  { label: "Me contacter", href: "/contact" },
];

export const menuDataAdmin = [
  {
    label: "Clients",
    href: "/clients",
    children: [
      {
        heading: "Clients",
        submenu: [
          {
            label: "Liste des clients",
            href: "/clients",
          },
          {
            label: "Ajouter un client",
            href: "/newclient",
          },
        ],
      },
    ],
  },
];

export const menuDataUser = (id) => [
  {
    label: "Dashboard",
    href: `/dashboard/${id}`,
    children: [
      {
        heading: "Nutrition",
        submenu: [
          {
            label: "Dashboard",
            href: `/dashboard/${id}`,
          },
        ],
      },
      {
        heading: "Corps",
        submenu: [
          {
            label: "Dashboard Mensurations",
            href: `/mensurationdashboard/${id}`,
          },
          {
            label: "Dashboard Poids",
            href: `/weightdashboard/${id}`,
          },
        ],
      },
    ],
  },
  {
    label: "Weekly check-in",
    href: `/weeklycheckinform/${id}`,
    children: [
      {
        heading: "Documentation",
        submenu: [
          { label: "Comment remplir le cheek-in?", href: "#" },
          {
            label: "Comment prendre ses mesures?",
            href: "#",
          },
        ],
      },
    ],
  },
  {
    label: "Ajout du poids",
    href: `/weightupdate/${id}`,
    children: [
      {
        heading: "Questions",
        submenu: [
          { label: "Pourquoi se peser ?", href: "#" },
          {
            label: "Comment choisir sa balance ?",
            href: "#",
          },
        ],
      },
    ],
  },
  {
    label: "Nutrition",
    href: "#",
    children: [
      {
        heading: "Tips",
        submenu: [
          {
            label: "Liste des protéines, lipides et glucides",
            href: "#",
          },
          {
            label: "Comment manger plus de protéinés au quotidien?",
            href: "#",
          },
          {
            label: "Exemples de desserts protéinés",
            href: "#",
          },
          {
            label: "Idées de petits-déjeuners et de collations",
            href: "#",
          },
        ],
      },
      {
        heading: "Fiches techniques",
        submenu: [
          {
            label: "Gérer un buffet à volonter",
            href: "#",
          },
          {
            label: "Gérer un repas de famille",
            href: "#",
          },
          {
            label: "Plusieurs jours en famille, comment faire?",
            href: "#",
          },
        ],
      },
    ],
  },
  {
    label: "Recettes",
    href: "#",
    children: [
      {
        heading: "Salées",
        submenu: [
          {
            label: "Tarte",
            href: "#",
          },
          {
            label: "Salades",
            href: "#",
          },
        ],
      },
      {
        heading: "Sucrées",
        submenu: [
          {
            label: "Goûter",
            href: "#",
          },
          {
            label: "Yaourts",
            href: "#",
          },
        ],
      },
    ],
  },
];
