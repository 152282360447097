import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Paper,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";
import ContactForm from "../components/contactForm/ContactForm";
import Header from "../components/Header";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const Contact = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="Demande de Coaching" subtitle="Formulaire" />
        </Box>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: "2rem" }}>
            <Typography
              variant="body1"
              color={colors.grey[100]}
              align="left"
              display="block"
            >
              Si tu es intéressé par le coaching, ou si tu as simplement des
              questions, je t'invite à remplir ce formulaire. <br></br> Ce
              formulaire me permet d'avoir, avant notre appel, une idée de ce
              que tu recherches. <br></br> Une fois le formulaire rempli, et
              envoyé, je te recontacte au plus vite par téléphone pour:
              <List>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Discuter plus en détails de tes objectifs" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="T'expliquer comment je peux t'aider au mieux" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="T'expliquer tous les détails du coaching" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Te donner les codes d'accés à la platforme de coaching" />
                </ListItem>
              </List>
              Cet appel est GRATUIT, il s'agit d'un premier échange. Tu pourras
              donc voir si ce que je propose te conviens.
            </Typography>
          </Paper>
        </Grid>

        <Box m={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <ContactForm />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Section>
  );
};

export default Contact;
