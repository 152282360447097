import React from "react";
import Hero from "../components/slider/Hero";
import InfoSection from "../components/homePage/InfoSection";
import Listings from "../components/homePage/Listings";
import Features from "../components/homePage/Features";
import { InfoData, InfoDataTwo } from "../components/homePage/data/InfoData";
import { SliderData } from "../components/slider/data/SliderData";

const Home = () => {
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection {...InfoData} />
      <Listings />
      {/* <Features />
      <InfoSection {...InfoDataTwo} /> */}
    </>
  );
};

export default Home;
