import FitnessOne from "../../images/fitness-3.png";
import FitnessTwo from "../../images/fitness-3.png";
import NutritionOne from "../../images/nutrition-4.png";
import NutritionTwo from "../../images/nutrition-2.jpg";
import MindsetOne from "../../images/mindset-1.jpg";
import Support from "../../images/mindset-3.png";

export const SliderData = [
  {
    title: "Un coaching SPORTIF personnalisé",
    details: "Sport/Maison",
    path: "/coachingtypes",
    label: "View Coaching",
    image: FitnessOne,
    alt: "Fitness",
  },
  {
    title: "Un coaching NUTRITION personnalisé",
    details: "Calcul des calories et des macros",
    path: "/coachingtypes",
    label: "View Coaching",
    image: NutritionOne,
    alt: "Nutrition",
  },
  {
    title: "Un support 24/24",
    details: "Une conversation whatsapp avec moi-même",
    path: "/coachingtypes",
    label: "View Support",
    image: Support,
    alt: "Support",
  },
];

export const SliderDataTwo = [
  {
    title: "Luxury Villa in Bali, Indonesia",
    details: "$4,280,000",
    path: "/homes",
    label: "View Home",
    image: FitnessOne,
    alt: "House",
  },
  {
    title: "Luxury Villa in Bali, Indonesia",
    details: "$4,280,000",
    path: "/homes",
    label: "View Home",
    image: FitnessTwo,
    alt: "House",
  },
  {
    title: "Luxury Villa in Bali, Indonesia",
    details: "$4,280,000",
    path: "/homes",
    label: "View Home",
    image: NutritionOne,
    alt: "House",
  },
  {
    title: "Luxury Villa in Bali, Indonesia",
    details: "$4,280,000",
    path: "/homes",
    label: "View Home",
    image: NutritionTwo,
    alt: "House",
  },
  {
    title: "Luxury Villa in Bali, Indonesia",
    details: "$4,280,000",
    path: "/homes",
    label: "View Home",
    image: MindsetOne,
    alt: "House",
  },
];
