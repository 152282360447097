import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom"; // Import Routes
import NavBar from "./app/components/navbar/NavBar";
import ClientTable from "./app/components/overviewOfAllClients/ClientTable";
import ClientDashboard from "./app/pages/ClientDashboard";
import UpdateMensurationsForm from "./app/components/clientCoachUpdates/clientSection/OLD_bodyMeasurementsForm/UpdateMensurationsForm";
import ClientRegistrationPage from "./app/pages/ClientRegistrationPage";
import SleepEntryForm from "./app/components/clientCoachUpdates/clientSection/OLD_sleepEntryForm/SleepEntryForm";
import WeeklyCheckIn from "./app/pages/WeeklyCheckIn";
import LoginPage from "./app/pages/authentification/login/LoginPage";
import PrivateRoute from "./app/services/PrivateRoute";
import UnauthorizedPage from "./app/pages/authentification/unauthorized/Unauthorized";
import { useUser } from "./app/pages/authentification/UserContext";
import GlobalStyle from "./globalStyles";
// import Dropdown from "./app/components/dropdown/Dropdown";
import Footer from "./app/components/footer/Footer";
import Home from "./app/pages";
import CoachingTypes from "./app/pages/CoachingTypes";
import Contact from "./app/pages/Contact";
import Homes from "./app/pages/Homes";
import Aos from "aos";
import "aos/dist/aos.css";
import MensurationsDashboard from "./app/pages/MensurationsDashboard";
import Weight from "./app/pages/Weight";
import Coach from "./app/pages/Coach";
import WeightDashboard from "./app/pages/WeightDashboard";
import Bilan from "./app/pages/Bilan";
import ChangePassword from "./app/pages/ChangePassword";
import Logout from "./app/pages/Logout";
import {
  menuData,
  menuDataAdmin,
  menuDataUser,
} from "./app/components/navbar/data/MenuData";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { user, setUser } = useUser();
  const [menu, setMenu] = useState(menuData);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    Aos.init({});
  }, []);

  useEffect(() => {
    if (user.token) {
      if (user.role === "ROLE_ADMIN") {
        setMenu(menuDataAdmin);
      } else if (user.role === "ROLE_USER") {
        setMenu(menuDataUser(user.id));
      }
    } else {
      setMenu(menuData);
    }
  }, [user]);

  const handleLogout = () => {
    // Clear user data and set the menu to default
    setUser({});
    setMenu(menuData);
  };

  return (
    <>
      <GlobalStyle />
      <NavBar toggle={toggle} menu={menu} />

      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/coach" exact element={<Coach />} />
        <Route path="/coachingtypes" exact element={<CoachingTypes />} />
        <Route path="/homes" exact element={<Homes />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
        <Route
          path="/home"
          element={
            <PrivateRoute
              component={Home}
              allowedRoles={["ROLE_ADMIN", "ROLE_COACH"]}
            />
          }
        />
        <Route
          path="/clients"
          element={
            <PrivateRoute
              component={ClientTable}
              allowedRoles={["ROLE_ADMIN", "ROLE_COACH"]}
            />
          }
        />
        <Route
          path="/dashboard/:clientID"
          element={
            <PrivateRoute
              component={ClientDashboard}
              allowedRoles={["ROLE_ADMIN", "ROLE_COACH", "ROLE_USER"]}
            />
          }
        />
        <Route path="/login" element={<LoginPage />} />

        {/* <Route
          path="/update/:clientID"
          element={
            <PrivateRoute
              component={UpdateClientInformationPage}
              allowedRoles={["ROLE_ADMIN", "ROLE_COACH"]}
            />
          }
        /> */}
        <Route
          path="/weightupdate/:clientID"
          element={
            <PrivateRoute component={Weight} allowedRoles={["ROLE_USER"]} />
          }
        />
        <Route
          path="/weeklycheckinform/:clientID"
          element={
            <PrivateRoute
              component={WeeklyCheckIn}
              allowedRoles={["ROLE_USER"]}
            />
          }
        />
        <Route
          path="/mensurationupdate/:clientID"
          element={
            <PrivateRoute
              component={UpdateMensurationsForm}
              allowedRoles={["ROLE_USER"]}
            />
          }
        />
        <Route
          path="/mensurationdashboard/:clientID"
          element={
            <PrivateRoute
              component={MensurationsDashboard}
              allowedRoles={["ROLE_USER"]}
            />
          }
        />
        <Route
          path="/weightdashboard/:clientID"
          element={
            <PrivateRoute
              component={WeightDashboard}
              allowedRoles={["ROLE_USER"]}
            />
          }
        />
        <Route
          path="/changepassword/:clientID"
          element={
            <PrivateRoute
              component={ChangePassword}
              allowedRoles={["ROLE_USER"]}
            />
          }
        />
        {/* COACH */}
        <Route
          path="/newclient"
          element={
            <PrivateRoute
              component={ClientRegistrationPage}
              allowedRoles={["ROLE_COACH", "ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="/bilan/:clientID"
          element={
            <PrivateRoute
              component={Bilan}
              allowedRoles={["ROLE_COACH", "ROLE_ADMIN"]}
            />
          }
        />

        {/* <Route
          path="/updateinfonutrition"
          element={
            <PrivateRoute
              component={UpdateForm}
              allowedRoles={["ROLE_COACH", "ROLE_ADMIN"]}
            />
          }
        /> */}
        <Route
          path="/sleepupdate/:clientID"
          element={
            <PrivateRoute
              component={SleepEntryForm}
              allowedRoles={["ROLE_USER"]}
            />
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
