import React from "react";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
} from "@mui/material";
import styled from "styled-components";

import InfoSection from "../../../components/homePage/InfoSection";
import { UnauthorizedData } from "../../../components/homePage/data/InfoData";

const UnauthorizedPage = (props) => {
  return <InfoSection {...UnauthorizedData} />;
};

export default UnauthorizedPage;
