import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import StatBox from "../../../statBox/StatBox";
import StraightenIcon from "@mui/icons-material/Straighten";
import { tokens } from "../../../../../theme";

const ClientMensurationStatBoxWrapper = ({ difference, subtitle, color }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const positiveDifferencesDataColor = "#4F709C";
  const negativeDifferencesDataColor = "#FBCB0A";
  const equalDifferencesDataColor = "#590696";

  console.log(difference);

  return (
    <Box
      width="100%"
      backgroundColor={colors.primary[400]}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <StatBox
        title={
          <span
            style={{
              color:
                difference > 0
                  ? positiveDifferencesDataColor
                  : difference < 0
                  ? negativeDifferencesDataColor
                  : equalDifferencesDataColor,
            }}
          >
            {`${difference || 0} g`}
          </span>
        }
        subtitle={subtitle}
        mesure={difference > 0 ? "↑" : difference < 0 ? "↓" : "="}
        icon={
          <StraightenIcon
            sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
          />
        }
      />
    </Box>
  );
};

export default ClientMensurationStatBoxWrapper;
