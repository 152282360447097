import React from "react";
import Header from "../components/Header";

import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";
import WeightEntryForm from "../components/clientCoachUpdates/clientSection/weightForm/WeightEntryForm";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const Weight = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="Poids" subtitle="A compléter chaque jour" />
        </Box>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: "2rem" }}>
            <Typography
              variant="body1"
              color={colors.grey[100]}
              align="left"
              display="block"
            >
              Le poids n'est qu'un chiffre, il ne te définit pas. <br />
              En tant que coach, il s'agit pour moi, d'un indicateur
              supplémentaire mais les mensurations restent le plus important.
              <br />
              Concernant le poids, je fais une moyenne de tous les poids de la
              semaine et je compare cette moyenne aux autres semaines. Donc le
              plus de données j'ai, le plus précis sera la moyenne finale.
            </Typography>
          </Paper>
        </Grid>

        <Box m={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <WeightEntryForm />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Section>
  );
};

export default Weight;
