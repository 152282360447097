import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_ENDPOINTS } from "../../../ApiService";
import apiService from "../../../ApiService";

import { Grid, Paper, useTheme, Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../../theme";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Header from "../Header";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: "#000",
    color: "#FFF",
  },
  "&.MuiTableCell-body": {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ClientTable = () => {
  const [clients, setClients] = useState([]);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await apiService.get(API_ENDPOINTS.CLIENTS);
        const clientsData = response.data;
        console.log(response.data);
        setClients(clientsData);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  return (
    <Section>
      <Box m="20px">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              display={smScreen ? "flex" : "block"}
              flexDirection={smScreen ? "row" : "column"}
              justifyContent={smScreen ? "space-between" : "start"}
              alignItems={smScreen ? "center" : "start"}
              m="10px 0"
            >
              <Header title="Clients" subtitle="Liste de tous les clients" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Nom</StyledTableCell>
                    <StyledTableCell align="right">Prénom</StyledTableCell>
                    <StyledTableCell align="right">Email</StyledTableCell>
                    <StyledTableCell align="right">
                      Date de Naissance
                    </StyledTableCell>
                    <StyledTableCell align="right">Age</StyledTableCell>
                    <StyledTableCell align="right">
                      Poids d'origine
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Poids actuel
                    </StyledTableCell>
                    <StyledTableCell align="right">Taille</StyledTableCell>
                    <StyledTableCell align="right">
                      Activity Level
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Nombre de workout par semaine
                    </StyledTableCell>
                    <StyledTableCell align="right">Coach</StyledTableCell>
                    <StyledTableCell align="right">Dashboard</StyledTableCell>
                    <StyledTableCell align="right">Bilan</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client) => (
                    <StyledTableRow key={client.clientID}>
                      <StyledTableCell align="right" component="th" scope="row">
                        {client.nom}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.prenom}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.email}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.datedenaissance}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.age}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.poidsdorigine}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.poidsactuel}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.taille}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.activitylevel}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.nbjourdeworkout}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {client.coach}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Link to={`/dashboard/${client.idUser}`}>
                          Dashboard
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Link to={`/bilan/${client.idUser}`}>Bilan</Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box mt={50}></Box> {/* Added Box component for additional spacing */}
      </Box>
    </Section>
  );
};

export default ClientTable;
