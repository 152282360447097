import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Paper, Typography, Button } from "@mui/material";
import apiService from "../../../../../ApiService";
import { API_ENDPOINTS } from "../../../../../ApiService";
import { FormTextField } from "../../../FormStyles";

const WeightEntryForm = () => {
  const { clientID } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newDatepoids: "",
    newPoids: "",
  });
  const [error, setError] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any existing errors
    try {
      const payload = {
        datepoids: formData.newDatepoids,
        poids: parseFloat(formData.newPoids), // Convert to floating-point number
        clientid: parseInt(clientID),
      };

      // Send payload to backend API
      const response = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_POIDS,
        payload
      );

      if (response.status === 201) {
        // Call the onSubmit callback passed from the parent component
        console.log(payload);
        navigate(`/dashboard/${clientID}`);
      } else {
        setError("Failed to add weight. Please try again.");
      }
    } catch (error) {
      setError("Error adding weight: " + error.message);
      console.error("Error adding weight:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          {" "}
          {/* Increased spacing between grid items */}
          <Grid item xs={12} sm={6}>
            <FormTextField
              // label="Date"
              name="newDatepoids"
              type="date"
              value={formData.newDatepoids}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="Weight"
              name="newPoids"
              type="number"
              value={formData.newPoids}
              onChange={handleInputChange}
              required
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          {" "}
          {/* Added space above the button */}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Add Weight
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default WeightEntryForm;
