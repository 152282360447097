import React from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import styled from "styled-components";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const CoachingTypes = () => {
  return (
    <Section>
      <Box component="section" sx={{ p: 4, bgcolor: "#f5f5f5" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 3, bgcolor: "#fff", boxShadow: 1 }}>
              <Typography variant="h4" component="h2" gutterBottom>
                Coaching Nutrition
              </Typography>
              <Typography variant="body1" component="div">
                Ce coaching consiste en:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Une mise à jour par semaine des apports énergétiques à avoir" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Un bilan à envoyer à la fin de chaque semaine" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Un accès à mon whatsapp pour me poser des questions" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Un site web facile d'accès qui permet un suivi très complet" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowRightAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Une bibliothèque de recettes avec macros" />
                </ListItem>
              </List>
              <Typography variant="body1" component="div"></Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 3, bgcolor: "#fff", boxShadow: 1 }}>
              <Typography variant="h4" component="h2" gutterBottom>
                Coaching Musculation
              </Typography>
              <Typography variant="body1" component="div">
                Executive coaching is designed to help leaders and executives
                enhance their leadership skills, improve their performance, and
                achieve their professional goals. Executive coaches provide
                support in areas such as:
              </Typography>
              <List sx={{ listStyleType: "disc" }}>
                <ListItem>
                  <ListItemText primary="Strategic thinking" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Decision-making" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Communication" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Team management" />
                </ListItem>
              </List>
              <Typography variant="body1" component="div">
                This type of coaching is often tailored to the specific needs of
                high-level professionals and organizations.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

export default CoachingTypes;
