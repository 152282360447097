class Nutrition {
  constructor(data) {
    this.nutritionID = data.nutritionID;
    this.datenutrition = new Date(data.datenutrition);
    this.apportcalorique = data.apportcalorique;
    this.proteines = data.proteines;
    this.glucides = data.glucides;
    this.lipides = data.lipides;
    this.repartitionfat = data.repartitionfat;
    this.clientid = data.clientid;
  }
}

export default Nutrition;
