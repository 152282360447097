import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { useUser } from "./authentification/UserContext";
import { API_ENDPOINTS } from "../../ApiService";
import apiService from "../../ApiService";
import Nutrition from "../models/Nutrition";
import NutritionTable from "../components/clientCoachUpdates/coachSection/nutritionTableUpdate/NutritionTable";
import WeightChart from "../components/clientDashboard/chart/WeightChart";
import ClientMensurationDataDashboard from "../components/clientDashboard/mensuration/ClientMensurationDataDashboard";
import {
  calculateDifferences,
  formatTimestamp,
} from "../components/clientDashboard/mensuration/dataProcessing/DataProcessing";

import {
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Added padding for better spacing */
`;

const Column = styled.div`
  width: 100%;
  padding: 10px; /* Added padding for better spacing */
`;

const RightColumn = styled.div`
  width: 100%;
  padding: 10px; /* Added padding for better spacing */
`;

const Separator = styled.div`
  width: 2px;
  background-color: #000;
  height: 100%;
`;

const Bilan = () => {
  const { clientID } = useParams(); // Retrieve the clientID from the route parameters

  const [clientData, setClientData] = useState(null);
  const [nutritionData, setNutritionData] = useState(null);
  const [latestWeight, setLatestWeight] = useState(0);
  const [poids, setPoids] = useState([]);
  const [nutritionPictures, setNutritionPictures] = useState([]);
  const [bodyPictures, setBodyPictures] = useState([]);
  const [timeRange, setTimeRange] = useState("month"); // Default time range is month

  const { user } = useUser();
  const { id } = user;

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await apiService.get(
          `${API_ENDPOINTS.CLIENTDASHBOARD}/${clientID}`
        );
        const clientData = response.data;
        setClientData(clientData);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientID) {
      fetchClientData();
    }
  }, [clientID]);

  useEffect(() => {
    console.log("clientID:", clientID);
    const fetchNutrition = async () => {
      try {
        if (clientData && id) {
          const response = await apiService.get(
            API_ENDPOINTS.CLIENT_NUTRITION(clientID)
          );
          const nutritionData = response.data.map(
            (data) => new Nutrition(data)
          );
          console.log("Dashboard poids", nutritionData);
          setNutritionData(nutritionData);
          console.log("Nutrition", nutritionData);
        }
      } catch (error) {
        console.error("Error fetching nutrition:", error);
      }
    };

    fetchNutrition();
  }, [clientData]);

  useEffect(() => {
    const fetchPoids = async () => {
      try {
        if (clientData && id) {
          // Check if clientData and clientID are not null
          const response = await apiService.get(
            API_ENDPOINTS.CLIENT_POIDS(clientID)
          );
          const poidsData = response.data;
          // Sorting the array by date in descending order
          poidsData.sort(
            (a, b) => new Date(b.datepoids) - new Date(a.datepoids)
          );
          console.log("Dashboard poids", poidsData);
          setPoids(poidsData);
          setLatestWeight(poidsData[0]?.poids || 0);
        }
      } catch (error) {
        console.error("Error fetching poids:", error);
      }
    };

    fetchPoids();
  }, [clientData]);

  useEffect(() => {
    const fetchNutritionPictures = async () => {
      try {
        const response = await apiService.get(
          API_ENDPOINTS.CLIENT_RETRIEVE_NUTRITION_PICTURES(clientID)
        );
        setNutritionPictures(response.data);
      } catch (error) {
        console.error("Error fetching nutrition pictures:", error);
      }
    };

    fetchNutritionPictures();
  }, [clientID]);

  useEffect(() => {
    const fetchBodyPictures = async () => {
      try {
        const response = await apiService.get(
          API_ENDPOINTS.CLIENT_RETRIEVE_BODY_PICTURES(clientID)
        );
        setBodyPictures(response.data);
      } catch (error) {
        console.error("Error fetching body pictures:", error);
      }
    };

    fetchBodyPictures();
  }, [clientID]);

  const filterDataByTimeRange = (data, range) => {
    const now = new Date();
    let filteredData;

    switch (range) {
      case "week":
        filteredData = data.filter(
          (item) =>
            new Date(item.datepoids) >=
            new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
        );
        break;
      case "month":
        filteredData = data.filter(
          (item) =>
            new Date(item.datepoids) >=
            new Date(now.setMonth(now.getMonth() - 1))
        );
        now.setMonth(now.getMonth() + 1); // Reset date
        break;
      case "year":
        filteredData = data.filter(
          (item) =>
            new Date(item.datepoids) >=
            new Date(now.setFullYear(now.getFullYear() - 1))
        );
        now.setFullYear(now.getFullYear() + 1); // Reset date
        break;
      default:
        filteredData = data;
    }

    return filteredData;
  };

  const weightMensurementsChartData = [
    {
      id: "Poids",
      color: "hsl(134, 70%, 50%)",
      data: filterDataByTimeRange(poids, timeRange)
        .map((item) => {
          const xValue = new Date(item.datepoids).getTime();
          const yValue = item.poids;
          if (isNaN(xValue) || yValue === undefined) {
            console.error("Invalid data item:", item);
            return null;
          }
          return {
            x: xValue,
            y: yValue,
          };
        })
        .filter((item) => item !== null) // Remove any null items from the array
        .sort((a, b) => a.x - b.x),
    },
  ];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  weightMensurementsChartData.forEach((dataObj) => {
    dataObj.data = dataObj.data.map((item) => ({
      x: formatTimestamp(item.x),
      y: item.y,
    }));
  });

  console.log(
    "Filtered Data for Range:",
    timeRange,
    weightMensurementsChartData
  );

  const name = clientData ? clientData.prenom : "Client"; // Provide a fallback value
  const headerTitle = `Bilan de ${name} `;

  return (
    <Section>
      <Box m="20px">
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title={headerTitle} subtitle="Check-in" />
        </Box>

        <Box m={2}>
          <FormControl
            variant="outlined"
            style={{ minWidth: 120, marginBottom: 20 }}
          >
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              label="Time Range"
            >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6}>
              {" "}
              {/* Increase the grid size */}
              <ColumnContainer>
                <Column>
                  <ClientMensurationDataDashboard
                    clientID={clientID}
                    apiEndpoints={API_ENDPOINTS}
                    user={user}
                    calculateDifference={(latest, previous) =>
                      latest - previous
                    }
                    formatTimestamp={formatTimestamp}
                    showStatBoxes={false}
                  />
                </Column>
              </ColumnContainer>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <ColumnContainer>
                <RightColumn>
                  <WeightChart
                    title="Weight"
                    subtitle="KG"
                    chartData={weightMensurementsChartData}
                    height="500px"
                  />
                </RightColumn>
              </ColumnContainer>
            </Grid>
            <Grid item xs={12}>
              <ColumnContainer>
                <Column>
                  {nutritionData && (
                    <NutritionTable
                      clientID={clientID}
                      initialNutritionData={nutritionData}
                      latestWeight={latestWeight}
                    />
                  )}
                </Column>
              </ColumnContainer>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {nutritionPictures.map((picture) => (
              <>
                <Grid item xs={12} sm={6} md={6}>
                  <ColumnContainer
                    style={{
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <Column>
                      <Box style={{ flex: "1 1 auto", padding: "5px" }}>
                        <img
                          src={picture.nutrientspicture}
                          alt="Nutrients"
                          style={{
                            width: "70%",
                            height: "600px", // Larger height                            marginBottom: "10px",
                          }}
                        />
                      </Box>
                    </Column>
                  </ColumnContainer>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ColumnContainer>
                    <RightColumn>
                      <Box style={{ flex: "1 1 auto", padding: "5px" }}>
                        <img
                          src={picture.caloriespicture}
                          alt="Calories"
                          style={{
                            width: "70%",
                            height: "600px",
                          }}
                        />
                      </Box>
                    </RightColumn>
                  </ColumnContainer>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            {bodyPictures.map((pictureBody, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4} md={4}>
                  <Box style={{ flex: "1 1 auto", padding: "5px" }}>
                    <img
                      src={pictureBody.frontpicture}
                      alt="Front picture"
                      style={{
                        width: "100%",
                        height: "600px",
                        marginBottom: "10px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box style={{ flex: "1 1 auto", padding: "5px" }}>
                    <img
                      src={pictureBody.backpicture}
                      alt="Back picture"
                      style={{
                        width: "100%",
                        height: "600px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box style={{ flex: "1 1 auto", padding: "5px" }}>
                    <img
                      src={pictureBody.sidepicture}
                      alt="Side picture"
                      style={{
                        width: "100%",
                        height: "600px",
                      }}
                    />
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Box>
    </Section>
  );
};

export default Bilan;
