import React from "react";
import { Typography } from "@mui/material";

function WeightDifferenceCard({ weightDifference }) {
  // Directly display the weight difference
  return (
    <Typography variant="h4" component="span" fontWeight="bold">
      {`${weightDifference} kg`} {/* Display weight difference with unit */}
    </Typography>
  );
}

export default WeightDifferenceCard;
