import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import styled from "styled-components";

import Life from "../components/images/Life.png";
import Musculation from "../components/images/Musculation.png";
import Sewing from "../components/images/Sewing.png";
import Travel from "../components/images/Travel.png";
import ToolsFitnessCoach from "../components/images/ToolsFitnessCoach.png";
import Certification from "../components/images/Certification.png";
import SewingWorkplace from "../components/images/SewingWorkplace.png";
import Alexia from "../components/images/Alexia.jpg";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const Image = styled(CardMedia)`
  height: 200px;
  border-radius: 5px;
  margin-bottom: 1rem; /* Add space below the image */
  object-fit: cover;
  object-position: bottom;
  object-position: 50% 80%; /* Adjust the position to personalize displayed part */
`;

const PortraitWrapper = styled.div`
  height: 450px; /* Adjusted height */
  width: 350px; /* Adjusted width */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; /* Add space below the image */
`;

const PortraitImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 5px;
`;

const GridContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: scale(1.05);
  }
`;

const Highlight = styled.span`
  background-color: #fcdc00;
`;

const Coach = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const renderCard = (image, text, delay) => (
    <Grid item xs={12} sm={6} md={4} key={text}>
      <StyledCard
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos-delay={delay}
      >
        <Image component="img" image={image} alt={text} />
        <CardContent>
          <Typography variant="body2">{text}</Typography>
        </CardContent>
      </StyledCard>
    </Grid>
  );

  return (
    <Container>
      <Section>
        <Typography variant="h2" align="center" sx={{ mb: "5px" }} gutterBottom>
          Mon parcours
        </Typography>
        <Box textAlign="center" marginBottom="4rem">
          <Image component="img" image={Life} alt="Life" />
          <Typography variant="body1">
            Bienvenue sur une courte présentation de ma vie !
          </Typography>
        </Box>

        <Grid container spacing={3} alignItems="center" marginBottom="4rem">
          <Grid item xs={12} md={8}>
            <Box textAlign="left">
              <Typography variant="h5" gutterBottom>
                Alexia
              </Typography>
              <Typography variant="body1">
                J'ai toujours été passionné par la technologie et l'innovation.{" "}
                <br></br> Mon parcours m'a amené à explorer divers domaines et à
                acquérir de nombreuses compétences: j'ai finis l'INSA de Rouen
                en spécialité "Informatique et Technologie de l'Information".{" "}
                <br></br>
                <br></br>
                Il y a 1 an et demi, 10kg en plus, j'ai décidé de me reprendre
                en main et de pousser la porte d'une salle de sport! Et... j'ai
                adoré!!<br></br>
                Je me suis donc formé pendant 1 an dans le domaine de la
                nutrition et du sport dans l'objectif de devenir coach. Je suis
                donc certifié Bayesian:{" "}
                <a
                  href="https://mennohenselmans.com/online-pt-course-fr/"
                  target="_blank"
                >
                  Lien de la formation
                </a>
                <br></br>
                J'ai suivi la formation en anglais pour avoir les dernières
                études scientifiques et être en contact direct avec le
                fondateur.
                <br></br>
                <br></br>
                J'aime partager mes connaissances et aider les autres à
                atteindre leurs objectifs.<br></br>
                Je suis originaire de Montpellier, et je vis aux Pays-Bas depuis
                maintenant 2 ans et demi.<br></br>
              </Typography>
              <Typography variant="body1">
                <Highlight>
                  Ah, et le jaune est ma couleur favorite !!!
                </Highlight>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <PortraitWrapper>
              <PortraitImage src={Alexia} alt="Portrait" />
            </PortraitWrapper>
          </Grid>
        </Grid>

        {/*      

        <Section>
          <Typography variant="h4" gutterBottom>
            Personal Life
          </Typography>
          <GridContainer container spacing={3}>
            {renderCard("family.jpg", "Family gatherings and celebrations", 0)}
            {renderCard(
              "travel.jpg",
              "Adventures and travels around the world",
              200
            )}
            {renderCard(
              "hobby.jpg",
              "Engaging in my favorite hobbies and activities",
              400
            )}
          </GridContainer>
        </Section> */}

        <Section>
          <Typography variant="h4" gutterBottom>
            Mes passions
          </Typography>
          <GridContainer container spacing={3}>
            {renderCard(Musculation, "La musculation", 0)}
            {renderCard(Sewing, "La couture et broderie", 200)}
            {renderCard(Travel, "Les voyages", 400)}
          </GridContainer>
        </Section>

        <Section>
          <Typography variant="h4" gutterBottom>
            Mes futures objectifs
          </Typography>
          <GridContainer container spacing={3}>
            {renderCard(
              ToolsFitnessCoach,
              "Pour aider le maximum de personnes à atteindre leurs propres personels",
              0
            )}
            {renderCard(
              Certification,
              "Continuer à me former. Un domaine qui me pationne est l'alimentation intuitive",
              200
            )}
            {renderCard(
              SewingWorkplace,
              "Coudre une plus grande variété d'object",
              400
            )}
          </GridContainer>
          <Box textAlign="center" marginTop="4rem">
            <Typography
              variant="body1"
              fontStyle="italic"
              color="textSecondary"
            >
              "L'avenir appartient à ceux qui croient en la beauté de leurs
              rêves." - Eleanor Roosevelt
            </Typography>
          </Box>
        </Section>
      </Section>
    </Container>
  );
};

export default Coach;
