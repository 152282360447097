import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "./Container";
import { useUser } from "../../pages/authentification/UserContext";

import logo from "../images/small-logos/websiteLogo.svg";
import UserProfile from "./UserProfile";
import { AlignJustify } from "lucide-react";
import MegaMenu from "./MegaMenu";
import MobileNavigationDrawer from "./MobileNavigationDrawer";
import { menuData, menuDataAdmin, menuDataUser } from "./data/MenuData";

const NavBar = ({ menu }) => {
  const { user } = useUser();
  const { role } = user;
  const { token, id } = user;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const drawerButtonRef = useRef(null);

  // let menu = menuData;
  // if (token) {
  //   if (role === "ROLE_ADMIN") {
  //     menu = menuDataAdmin;
  //   } else if (role === "ROLE_USER") {
  //     menu = menuDataUser(id);
  //   }
  // }

  return (
    <header className="nav__header">
      <Container>
        <div className="toolbar">
          <button
            ref={drawerButtonRef}
            className="menu_icon md:hidden"
            aria-haspopup="true"
            onClick={() => setIsDrawerOpen(true)}
          >
            {/* Mobile Hamburger menu */}
            <AlignJustify />
          </button>
          <Link className="logo__link" to={"/"}>
            <img src={logo} />
          </Link>

          <div className="hidden md:block">
            <MegaMenu menu={menu} />
          </div>
          <UserProfile token={token} />

          {/* Mobile navigation drawer */}
          <div className="md:hidden absolute">
            <MobileNavigationDrawer
              {...{ isDrawerOpen, setIsDrawerOpen, drawerButtonRef }}
            />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default NavBar;
