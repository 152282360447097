import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
} from "@mui/material";

import styled from "styled-components";
import apiService from "../../../ApiService";
import { API_ENDPOINTS } from "../../../ApiService";
import {
  FormContainer,
  FormTitle,
  FormPaper,
  FormTextField,
  FormButton,
} from "../FormStyles";

import Contact from "../../pages/Contact";

const FormSection = ({ label, name, options, value, onChange }) => (
  <Grid item xs={12}>
    <FormControl component="fieldset" fullWidth>
      <FormLabel
        component="legend"
        sx={{
          textDecoration: "underline",
          textDecorationColor: "black",
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup name={name} value={value} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </Grid>
);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    coachingType: "",
    budget: "",
    professionalActivity: "",
    physicalCondition: "",
    biggestChallenge: "",
    challengeResolution: "",
    idealBody: "",
    programImportance: "",
    readyToStart: "",
    motivation: "",
    phoneNumber: "",
    fullName: "",
    age: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiService.post(API_ENDPOINTS.CONTACT, formData);
      alert("Your form has been submitted successfully!");
      navigate(`/`);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <FormSection
            label="Quel type de coaching souhaites-tu?"
            name="coachingType"
            value={formData.coachingType}
            onChange={handleChange}
            options={[
              {
                value: "suivi quotidien",
                label:
                  "Un suivi personalisé (nutrition + sport) quotidien, avec possibilité de me joindre tous les jours",
              },
              {
                value: "suivi hebdomadaire",
                label:
                  "Un suivi personnalisé (nutrition + sport) avec un bilan en fin de chaque semaine",
              },
              {
                value: "programme entraînement",
                label: "Un programme d'entraînement",
              },
              {
                value: "programme nutrition",
                label: "Un programme de nutrition",
              },
            ]}
          />

          <FormSection
            label="Dans le but d'atteindre tes objectifs, quel prix serais-tu prêt à investir chaque mois?"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            options={[
              { value: "100-150", label: "Entre 100 et 150€/mois" },
              { value: "150-250", label: "Entre 150 et 250€/mois" },
              { value: "250-400", label: "Entre 250 et 400€/mois" },
              {
                value: "no limit",
                label:
                  "Je n'ai pas de limite, atteindre mo objectif est plus important",
              },
            ]}
          />

          <Grid item xs={12}>
            <TextField
              required
              id="professionalActivity"
              name="professionalActivity"
              label="En ce moment, quelle est ton activité professionnelle? Es-tu satisfait de ton travail?"
              fullWidth
              variant="outlined"
              multiline
              value={formData.professionalActivity}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="physicalCondition"
              name="physicalCondition"
              label="Comment te sens-tu physiquement ajourd'hui?"
              fullWidth
              variant="outlined"
              value={formData.physicalCondition}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="biggestChallenge"
              name="biggestChallenge"
              label="Qu'aimerais-tu changer en toi pour être 100% satisfait?"
              fullWidth
              variant="outlined"
              value={formData.biggestChallenge}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="challengeResolution"
              name="challengeResolution"
              label="Qu'est-ce qui, en ce moment, t'empêche d'y arriver?"
              fullWidth
              variant="outlined"
              value={formData.challengeResolution}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="idealBody"
              name="idealBody"
              label="Dans l'idéal, quel type de corps aimerais-tu avoir? Quelle silhouette te ferait plaisir ou quel poids aimerais-tu avoir"
              fullWidth
              variant="outlined"
              value={formData.idealBody}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="programImportance"
              name="programImportance"
              label="Qu'est-ce qui est important pour toi dans un coaching?"
              fullWidth
              variant="outlined"
              value={formData.programImportance}
              onChange={handleChange}
            />
          </Grid>

          <FormSection
            label="Quand serais-tu prêt à commencer le coaching?"
            name="readyToStart"
            value={formData.readyToStart}
            onChange={handleChange}
            options={[
              { value: "Des que possible", label: "Dès que possible" },
              { value: "1-2 semaines", label: "Dans 1 ou 2 semaines" },
              { value: "1 mois ou plus", label: "Dans 1 mois ou plus" },
            ]}
          />

          <Grid item xs={12}>
            <TextField
              required
              id="motivation"
              name="motivation"
              label="Qu'est-ce qui t'a poussé à me contacter pour avoir de l'aide?"
              fullWidth
              variant="outlined"
              value={formData.motivation}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="phoneNumber"
              name="phoneNumber"
              label="Quel est ton numéro de téléphone pour te recontacter ?"
              fullWidth
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="fullName"
              name="fullName"
              label="Nom et Prénom"
              fullWidth
              variant="outlined"
              value={formData.fullName}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="age"
              name="age"
              label="Âge"
              fullWidth
              variant="outlined"
              value={formData.age}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Envoyer
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ContactForm;
