import React from "react";
import ClientRegistrationForm from "../components/clientCoachUpdates/coachSection/clientRegistration/ClientRegistrationForm";
import Header from "../components/Header";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const ClientRegistrationPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="Fiche nouveau client" subtitle="A compléter" />
        </Box>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: "2rem" }}>
            <Typography
              variant="body1"
              color={colors.grey[100]}
              align="left"
              display="block"
            >
              A remplir...
            </Typography>
          </Paper>
        </Grid>

        <Box m={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <ClientRegistrationForm />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Section>
  );
};

export default ClientRegistrationPage;
