import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useUser } from "./authentification/UserContext";
import { API_ENDPOINTS } from "../../ApiService";
import apiService from "../../ApiService";

import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";

import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import StatBox from "../components/statBox/StatBox";
import BarChart from "../components/BarChart";
import Header from "../components/Header";
import Nutrition from "../models/Nutrition";
import WeightDifferenceCard from "../components/clientDashboard/WeightDifferenceCard ";

import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import PieChart from "../components/PieChart";
import ScaleIcon from "@mui/icons-material/Scale";
import SetMealIcon from "@mui/icons-material/SetMeal";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const ClientDashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  const { clientID } = useParams(); // Retrieve the clientID from the route parameters
  const [clientData, setClientData] = useState(null);
  const [poids, setPoids] = useState([]);
  const [nutritionData, setNutritionData] = useState([]);
  const navigate = useNavigate();

  console.log("clientID", clientID);

  const { user } = useUser();
  const { id } = user;

  // Clear data when clientID changes
  useEffect(() => {
    setClientData(null);
    setPoids([]);
    setNutritionData([]);
  }, [clientID]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await apiService.get(
          `${API_ENDPOINTS.CLIENTDASHBOARD}/${clientID}`
        );
        const clientData = response.data;
        setClientData(clientData);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientID) {
      fetchClientData();
    }
  }, [clientID]);

  useEffect(() => {
    const fetchPoids = async () => {
      try {
        if (clientID) {
          const response = await apiService.get(
            API_ENDPOINTS.CLIENT_POIDS(clientID)
          );
          const poidsData = response.data;
          // Sorting the array by date in descending order
          poidsData.sort(
            (a, b) => new Date(b.datepoids) - new Date(a.datepoids)
          );
          setPoids(poidsData);
        }
      } catch (error) {
        console.error("Error fetching poids:", error);
      }
    };

    fetchPoids();
  }, [clientID]);

  useEffect(() => {
    const fetchNutrition = async () => {
      try {
        if (clientID) {
          const response = await apiService.get(
            API_ENDPOINTS.CLIENT_NUTRITION(clientID)
          );
          const nutritionData = response.data.map(
            (data) => new Nutrition(data)
          );
          setNutritionData(nutritionData);
          console.log("nutritionData", nutritionData);
        }
      } catch (error) {
        console.error("Error fetching nutrition:", error);
      }
    };

    fetchNutrition();
  }, [clientID]);

  const weightMensurementsChartData = [
    {
      id: "Poids",
      color: "hsl(134, 70%, 50%)",
      data: poids
        .map((item) => ({
          x: new Date(item.datepoids).getTime(),
          y: item.poids,
        }))
        .sort((a, b) => a.x - b.x),
    },
  ];

  // Function to format timestamp to "Month Day" format
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Convert the timestamps in the chart data to "Month Day" format
  weightMensurementsChartData.forEach((dataObj) => {
    dataObj.data = dataObj.data.map((item) => ({
      x: formatTimestamp(item.x), // Convert timestamp to "Month Day" format
      y: item.y,
    }));
  });

  const formattedNutritionData = nutritionData.flatMap((nutri, index) => [
    {
      id: `Protein`,
      label: `Protein`,
      value: nutri.proteines,
      color: `hsl(${index * 30}, 70%, 50%)`,
    },
    {
      id: `Carbs`,
      label: `Carbs`,
      value: nutri.glucides,
      color: `hsl(${index * 30}, 70%, 50%)`,
    },
    {
      id: `Fat`,
      label: `Fat`,
      value: nutri.lipides,
      color: `hsl(${index * 30}, 70%, 50%)`,
    },
  ]);

  const calculateProteines = (weight, multiplier) => {
    return (weight * multiplier).toFixed(0);
  };

  const calculateLipides = (apportcalorique, multiplier) => {
    return (((apportcalorique * 0.4) / 9) * multiplier).toFixed(0);
  };

  const calculateGlucides = (apportcalorique, proteines, multiplier) => {
    const remainingCalories =
      apportcalorique - apportcalorique * 0.4 - proteines * 4;
    return ((remainingCalories / 4) * multiplier).toFixed(0);
  };

  const formattedNutritionDataPerMeal = nutritionData.flatMap(
    (nutri, index) => [
      {
        meal: "Breakfast",
        protein: calculateProteines(clientData?.poidsactuel, 0.4),
        proteinColor: "hsl(296, 70%, 50%)",
        fat: calculateLipides(nutritionData[0]?.apportcalorique, 0.25),
        fatColor: "hsl(97, 70%, 50%)",
        carbs: calculateGlucides(
          nutritionData[0]?.apportcalorique,
          nutritionData[0]?.proteines,
          0.2
        ),
        carbsColor: "hsl(340, 70%, 50%)",
      },
      {
        meal: "Lunch",
        protein: calculateProteines(clientData?.poidsactuel, 0.3),
        proteinColor: "hsl(296, 70%, 50%)",
        fat: calculateLipides(nutritionData[0]?.apportcalorique, 0.25),
        fatColor: "hsl(97, 70%, 50%)",
        carbs: calculateGlucides(
          nutritionData[0]?.apportcalorique,
          nutritionData[0]?.proteines,
          0.15
        ),
        carbsColor: "hsl(340, 70%, 50%)",
      },
      {
        meal: "Snack",
        protein: calculateProteines(clientData?.poidsactuel, 0.3),
        proteinColor: "hsl(296, 70%, 50%)",
        fat: calculateLipides(nutritionData[0]?.apportcalorique, 0.1),
        fatColor: "hsl(97, 70%, 50%)",
        carbs: calculateGlucides(
          nutritionData[0]?.apportcalorique,
          nutritionData[0]?.proteines,
          0.2
        ),
        carbsColor: "hsl(340, 70%, 50%)",
      },
      {
        meal: "Diner",
        protein: calculateProteines(clientData?.poidsactuel, 0.6),
        proteinColor: "hsl(296, 70%, 50%)",
        fat: calculateLipides(nutritionData[0]?.apportcalorique, 0.4),
        fatColor: "hsl(97, 70%, 50%)",
        carbs: calculateGlucides(
          nutritionData[0]?.apportcalorique,
          nutritionData[0]?.proteines,
          0.5
        ),
        carbsColor: "hsl(340, 70%, 50%)",
      },
    ]
  );

  if (!clientData) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  // Calculate the weight difference and subtitle before rendering components
  const weightDifference =
    poids && poids.length > 0
      ? (poids[0].poids - (clientData?.poidsdorigine ?? 0)).toFixed(1)
      : 0; // default to 0 if poids is undefined or empty

  const subtitle = weightDifference >= 0 ? "Weight gain" : "Weight loss";
  const isDataLoaded = poids && poids.length > 0;

  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}

        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box>
        </Box>

        {/* GRID & CHARTS */}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={
                  <WeightDifferenceCard weightDifference={weightDifference} />
                }
                subtitle={
                  isDataLoaded
                    ? weightDifference >= 0
                      ? "Weight gain"
                      : "Weight loss"
                    : "Loading..."
                }
                mesure="kg"
                progress="0.75"
                increase="+14%"
                icon={
                  <ScaleIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {nutritionData.length > 0 ? (
                <StatBox
                  title={`${nutritionData[0].apportcalorique} Kcal`}
                  subtitle="Calories per day"
                  // mesure="Kcal"
                  progress="0.50"
                  increase="+21%"
                  icon={
                    <RestaurantIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {nutritionData.length > 0 ? (
                <StatBox
                  title={`${nutritionData[0].proteines} g`}
                  subtitle="Proteins per day"
                  // mesure="g"
                  progress="0.30"
                  increase="+5%"
                  icon={
                    <SetMealIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {nutritionData.length > 0 ? (
                <StatBox
                  title={`${nutritionData[0].lipides} g`}
                  subtitle="Fat per day"
                  // mesure="g"
                  progress="0.80"
                  increase="+43%"
                  icon={
                    <BakeryDiningIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {nutritionData.length > 0 ? (
                <StatBox
                  title={`${nutritionData[0].glucides} g`}
                  subtitle="Carbs per day"
                  // mesure="g"
                  progress="0.80"
                  increase="+43%"
                  icon={
                    <LocalPizzaIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Grid>
          {nutritionData.length > 0 && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box backgroundColor={colors.primary[400]}>
                  <Box
                    mt="25px"
                    p="0 30px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box p="30px">
                      <Typography variant="h5" fontWeight="600">
                        Macros per day (g)
                      </Typography>
                    </Box>
                  </Box>
                  <Box height="350px" mt="-20px">
                    <PieChart
                      dataNutrition={formattedNutritionData}
                      isDashboard={true}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box backgroundColor={colors.primary[400]} p="0 30px">
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                    sx={{ padding: "30px 30px 0 30px" }}
                  >
                    Macros per Meal per Day (g)
                  </Typography>
                  <Box height="350px" mt="-20px">
                    <BarChart
                      dataNutritionPerMeal={formattedNutritionDataPerMeal}
                      isDashboard={true}
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Section>
  );
};

export default ClientDashboard;
