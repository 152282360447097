import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import apiService from "../../../../../ApiService";
import { API_ENDPOINTS } from "../../../../../ApiService";
import {
  FormContainer,
  FormTitle,
  FormPaper,
  FormTextField,
  FormButton,
} from "../../../FormStyles";

const UpdateMensurationsForm = () => {
  const { clientID } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    datemensuration: "",
    taille: "",
    fesses: "",
    bicepsdroit: "",
    bicepsgauche: "",
    cuissedroite: "",
    cuissegauche: "",
    molletdroit: "",
    molletgauche: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        datemensuration: formData.datemensuration,
        taille: parseFloat(formData.taille),
        fesses: parseFloat(formData.fesses),
        bicepsdroit: parseFloat(formData.bicepsdroit),
        bicepsgauche: parseFloat(formData.bicepsgauche),
        cuissedroite: parseFloat(formData.cuissedroite),
        cuissegauche: parseFloat(formData.cuissegauche),
        molletdroit: parseFloat(formData.molletdroit),
        molletgauche: parseFloat(formData.molletgauche),
        clientid: parseInt(clientID), // Convert clientID to an integer
      };

      const response = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_MENSURATIONS,
        payload
      );

      if (response.status === 201) {
        // Call the onSubmit callback passed from the parent component
        console.log(payload);
        navigate(`/dashboard/${clientID}`);
      }
    } catch (error) {
      console.error("Error updating mensurations:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <FormContainer container justifyContent="center">
      <FormPaper>
        <form onSubmit={handleFormSubmit}>
          <FormTitle variant="h4" gutterBottom>
            Update Mensurations
          </FormTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTextField
                // label="Date"
                name="datemensuration"
                type="date"
                value={formData.datemensuration}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextField
                label="Taille"
                name="taille"
                type="number"
                value={formData.taille}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextField
                label="Fesses"
                name="fesses"
                type="number"
                value={formData.fesses}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextField
                label="Biceps Droit"
                name="bicepsdroit"
                type="number"
                value={formData.bicepsdroit}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label="Biceps Gauche"
                name="bicepsgauche"
                type="number"
                value={formData.bicepsgauche}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label="Cuisse Droite"
                name="cuissedroite"
                type="number"
                value={formData.cuissedroite}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label="Cuisse Gauche"
                name="cuissegauche"
                type="number"
                value={formData.cuissegauche}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label="Mollet Droit"
                name="molletdroit"
                type="number"
                value={formData.molletdroit}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label="Mollet Gauche"
                name="molletgauche"
                type="number"
                value={formData.molletgauche}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <FormButton type="submit" variant="contained" color="primary">
            Update Mensurations
          </FormButton>
        </form>
      </FormPaper>
    </FormContainer>
  );
};

export default UpdateMensurationsForm;
