import React from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LineChart from "../../LineChart";

const WeightChart = ({ title, subtitle, chartData, height }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box backgroundColor={colors.primary[400]}>
      <Box
        mt="25px"
        p="0 30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[500]}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box>
          <IconButton>
            <DownloadOutlinedIcon
              sx={{
                fontSize: "26px",
                color: colors.greenAccent[500],
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box height={height} m="-20px 0 0 0">
        <LineChart dataUsed={chartData} isDashboard={true} />
      </Box>
    </Box>
  );
};

export default WeightChart;
