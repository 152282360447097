import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useUser } from "./authentification/UserContext";
import { API_ENDPOINTS } from "../../ApiService";
import apiService from "../../ApiService";

import { Box, useTheme, useMediaQuery } from "@mui/material";
import styled from "styled-components";

import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import Header from "../components/Header";
import WeightChart from "../components/clientDashboard/chart/WeightChart";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const WeightDashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  const { clientID } = useParams(); // Retrieve the clientID from the route parameters
  const [clientData, setClientData] = useState(null);
  const [poids, setPoids] = useState([]);

  const { user } = useUser();
  const { id } = user;

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await apiService.get(
          `${API_ENDPOINTS.CLIENTDASHBOARD}/${clientID}`
        );
        const clientData = response.data;
        setClientData(clientData);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientID) {
      fetchClientData();
    }
  }, [clientID]);

  useEffect(() => {
    const fetchPoids = async () => {
      try {
        if (clientData && id) {
          // Check if clientData and clientID are not null
          const response = await apiService.get(
            API_ENDPOINTS.CLIENT_POIDS(clientID)
          );
          const poidsData = response.data;
          // Sorting the array by date in descending order
          poidsData.sort(
            (a, b) => new Date(b.datepoids) - new Date(a.datepoids)
          );
          console.log("Dashboard poids", poidsData);

          setPoids(poidsData);
        }
      } catch (error) {
        console.error("Error fetching poids:", error);
      }
    };

    fetchPoids();
  }, [clientData]);

  const weightMensurementsChartData = [
    {
      id: "Poids",
      color: "hsl(134, 70%, 50%)",
      data: poids
        .map((item) => ({
          x: new Date(item.datepoids).getTime(),
          y: item.poids,
        }))
        .sort((a, b) => a.x - b.x),
    },
  ];

  // Function to format timestamp to "Month Day" format
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Convert the timestamps in the chart data to "Month Day" format
  weightMensurementsChartData.forEach((dataObj) => {
    dataObj.data = dataObj.data.map((item) => ({
      x: formatTimestamp(item.x), // Convert timestamp to "Month Day" format
      y: item.y,
    }));
  });
  console.log("weightMensurementsChartData", weightMensurementsChartData);

  if (!clientData) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Section>
      <Box m="20px">
        {/* HEADER */}

        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header
            title="Tableau de bord du Poids"
            subtitle="Poids au cours de l'année"
          />
        </Box>

        {/* GRID & CHARTS */}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <WeightChart
                title="Weight"
                subtitle="KG"
                chartData={weightMensurementsChartData}
                height="500px"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

export default WeightDashboard;
