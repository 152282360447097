import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import apiService from "../../../../../ApiService";
import { API_ENDPOINTS } from "../../../../../ApiService";
import {
  FormContainer,
  FormTitle,
  FormPaper,
  FormTextField,
  FormButton,
} from "../../../FormStyles";

const SleepEntryForm = () => {
  const { clientID } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    sleepWeeklyDate: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        sleepweeklydate: formData.sleepWeeklyDate,
        monday: formData.monday,
        tuesday: formData.tuesday,
        wednesday: formData.wednesday,
        thursday: formData.thursday,
        friday: formData.friday,
        saturday: formData.saturday,
        sunday: formData.sunday,
        clientid: parseInt(clientID),
      };

      // Send payload to backend API
      const response = await apiService.post(
        API_ENDPOINTS.CLIENT_ADD_SLEEP,
        payload
      );

      if (response.status === 201) {
        // Call the onSubmit callback passed from the parent component
        console.log(payload);
        navigate(`/dashboard/${clientID}`);
      }
    } catch (error) {
      console.error("Error adding sleep data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <FormContainer>
      <FormPaper>
        <form onSubmit={handleFormSubmit}>
          <FormTitle variant="h4" gutterBottom>
            Enter Sleep Data
          </FormTitle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                // label="Weekly Date"
                name="sleepWeeklyDate"
                type="date"
                value={formData.sleepWeeklyDate}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <h6>The data should have this format: e.g., "7h 30m"</h6>
              {[
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
                "sunday",
              ].map((day) => (
                <FormTextField
                  key={day}
                  label={capitalizeFirstLetter(day)}
                  name={day}
                  type="text"
                  value={formData[day]}
                  onChange={handleInputChange}
                  required
                />
              ))}
            </Grid>
          </Grid>
          <FormButton type="submit" variant="contained" color="primary">
            Add Sleep Data
          </FormButton>
        </form>
      </FormPaper>
    </FormContainer>
  );
};

// Helper function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default SleepEntryForm;
