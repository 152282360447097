export const calculateDifferences = (mensurations, calculateDifference) => {
  const differences = {};
  if (mensurations.length >= 2) {
    const latestMensuration = mensurations[mensurations.length - 1];
    const previousMensuration = mensurations[mensurations.length - 2];

    differences.bicepsdroit = calculateDifference(
      latestMensuration.bicepsdroit,
      previousMensuration.bicepsdroit
    );
    differences.bicepsgauche = calculateDifference(
      latestMensuration.bicepsgauche,
      previousMensuration.bicepsgauche
    );
    differences.cuissedroite = calculateDifference(
      latestMensuration.cuissedroite,
      previousMensuration.cuissedroite
    );
    differences.cuissegauche = calculateDifference(
      latestMensuration.cuissegauche,
      previousMensuration.cuissegauche
    );
    differences.fesses = calculateDifference(
      latestMensuration.fesses,
      previousMensuration.fesses
    );
    differences.taille = calculateDifference(
      latestMensuration.taille,
      previousMensuration.taille
    );
    differences.molletdroit = calculateDifference(
      latestMensuration.molletdroit,
      previousMensuration.molletdroit
    );
    differences.molletgauche = calculateDifference(
      latestMensuration.molletgauche,
      previousMensuration.molletgauche
    );
  }
  return differences;
};

export const prepareChartData = (
  mensurations,
  formatTimestamp,
  bodyPartColors
) => {
  return [
    {
      id: "Biceps droit/gauche",
      color: bodyPartColors[0],
      data: mensurations
        .map((item) => ({
          x: formatTimestamp(new Date(item.datemensuration).getTime()),
          y: (item.bicepsdroit + item.bicepsgauche) / 2, // Calculate the mean of biceps droit and biceps gauche
        }))
        .sort((a, b) => a.x - b.x),
    },
    {
      id: "Cuisse droite/gauche",
      color: bodyPartColors[2],
      data: mensurations
        .map((item) => ({
          x: formatTimestamp(new Date(item.datemensuration).getTime()),
          y: (item.cuissedroite + item.cuissegauche) / 2,
        }))
        .sort((a, b) => a.x - b.x),
    },
    {
      id: "Fesses",
      color: bodyPartColors[4],
      data: mensurations
        .map((item) => ({
          x: formatTimestamp(new Date(item.datemensuration).getTime()),
          y: item.fesses,
        }))
        .sort((a, b) => a.x - b.x),
    },
    {
      id: "Taille",
      color: bodyPartColors[5],
      data: mensurations
        .map((item) => ({
          x: formatTimestamp(new Date(item.datemensuration).getTime()),
          y: item.taille,
        }))
        .sort((a, b) => a.x - b.x),
    },
    {
      id: "Mollet Droit/Gauche",
      color: bodyPartColors[6],
      data: mensurations
        .map((item) => ({
          x: formatTimestamp(new Date(item.datemensuration).getTime()),
          y: (item.molletdroit + item.molletgauche) / 2,
        }))
        .sort((a, b) => a.x - b.x),
    },
  ];
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = { month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
