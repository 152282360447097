import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

// Stub for a custom layer
// It displays totals on top of bars
// This stub only works for vertical bars
const BarTotalsLayer = (props) => {
  const labelOffset = 10;
  const labelFontSize = 15;
  if (props.bars.length === 0) return null;
  // compute totals for each index/bar
  const totals = {};
  const bandwidth = props.bars[0].width;
  props.bars.forEach((bar) => {
    const indexValue = bar.data.indexValue;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });
  // place text elements above the bars
  const labels = Object.keys(totals).map((indexValue) => {
    const x = props.xScale(indexValue) + bandwidth / 2;
    const y = props.yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={"total." + indexValue}
        x={x}
        y={y}
        textAnchor={"middle"}
        fontWeight={"bold"}
        fontSize={labelFontSize}
      >
        {totals[indexValue]}
      </text>
    );
  });
  return <>{labels}</>;
};

const BarChart = ({ dataNutritionPerMeal, isDashboard }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveBar
      data={dataNutritionPerMeal}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
              fontSize: 18,
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
              fontSize: 15,
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
            fontSize: 18,
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
            color: colors.grey[100],
          },
        },
        labels: {
          text: {
            fontSize: 18,
          },
        },
      }}
      keys={["protein", "carbs", "fat"]}
      indexBy="meal"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.4}
      innerPadding={1}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      valueFormat=" <-"
      colors={{ scheme: "set2" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      // fill={[
      //   {
      //     match: {
      //       id: "protein",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "fat",
      //     },
      //     id: "lines",
      //   },
      // ]}
      borderRadius={1}
      borderColor="black"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Meal",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "g",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      layers={[
        "grid",
        "axes",
        "markers",
        "bars",
        "legends",
        "annotations",
        BarTotalsLayer,
      ]}
      labelTextColor="black"
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemTextColor: "#000",
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default BarChart;
