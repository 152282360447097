import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import { API_ENDPOINTS } from "../../../../../ApiService";
import apiService from "../../../../../ApiService";

const NutritionTable = ({ clientID, initialNutritionData, latestWeight }) => {
  const initialData =
    initialNutritionData && initialNutritionData.length > 0
      ? initialNutritionData[0]
      : {};

  console.log("Initial nutrition data received:", initialNutritionData);

  const [nutritionData, setNutritionData] = useState(initialData);
  const [editableApportCalorique, setEditableApportCalorique] = useState(
    initialData.apportcalorique || ""
  );
  const [editableProteines, setEditableProteines] = useState(
    initialData.proteines || ""
  );
  const [editableGlucides, setEditableGlucides] = useState(
    initialData.glucides || ""
  );
  const [editableLipides, setEditableLipides] = useState(
    initialData.lipides || ""
  );
  const [editableRepartitionFat, setEditableRepartitionFat] = useState(
    initialData.repartitionfat || 0.25
  );

  useEffect(() => {
    console.log("Initial nutrition data received:", initialNutritionData);

    if (initialNutritionData && initialNutritionData.length > 0) {
      const nutrition = initialNutritionData[0];
      setNutritionData(nutrition);
      setEditableApportCalorique(nutrition.apportcalorique || "");
      setEditableProteines(nutrition.proteines || "");
      setEditableGlucides(nutrition.glucides || "");
      setEditableLipides(nutrition.lipides || "");
      setEditableRepartitionFat(nutrition.repartitionfat || 0.25);
    }
  }, [initialNutritionData]);

  const handleApportCaloriqueChange = (event) => {
    const newValue = event.target.value;
    setEditableApportCalorique(newValue);

    const updatedProteines = latestWeight * 1.8;
    const updatedLipides = (newValue * editableRepartitionFat) / 9;
    const updatedGlucides =
      (newValue - updatedProteines * 4 - updatedLipides * 9) / 4;

    setEditableProteines(updatedProteines.toFixed(2));
    setEditableLipides(updatedLipides.toFixed(2));
    setEditableGlucides(updatedGlucides.toFixed(2));
  };

  const handleRepartitionFatChange = (event) => {
    const newRepartitionFat = event.target.value;
    setEditableRepartitionFat(newRepartitionFat);

    const updatedLipides = (editableApportCalorique * newRepartitionFat) / 9;
    const updatedGlucides =
      (editableApportCalorique - editableProteines * 4 - updatedLipides * 9) /
      4;

    setEditableLipides(updatedLipides.toFixed(2));
    setEditableGlucides(updatedGlucides.toFixed(2));
  };

  const handleSave = async () => {
    const updatedData = {
      ...nutritionData,
      apportcalorique: editableApportCalorique,
      proteines: editableProteines,
      glucides: editableGlucides,
      lipides: editableLipides,
      datenutrition: new Date(),
      repartitionfat: editableRepartitionFat,
      clientid: clientID,
    };

    try {
      let response;
      if (initialNutritionData && initialNutritionData.length > 0) {
        response = await apiService.put(
          API_ENDPOINTS.COACH_UPDATE_CLIENT_INFORMATION_NUTRITON_PER_WEEK(
            clientID
          ),
          updatedData
        );
      } else {
        response = await apiService.post(
          API_ENDPOINTS.ADD_CLIENT_INFORMATION_NUTRITION,
          updatedData
        );
      }
      console.log("Nutrition data saved:", response.data);
      setNutritionData(response.data);
    } catch (error) {
      console.error("Error saving nutrition data:", error);
    }
  };

  console.log("nutritionData", nutritionData);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Apport Calorique</TableCell>
            <TableCell>Proteines</TableCell>
            <TableCell>Glucides</TableCell>
            <TableCell>Lipides</TableCell>
            <TableCell>Repartition Fat</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{nutritionData.apportcalorique}</TableCell>
            <TableCell>{nutritionData.proteines}</TableCell>
            <TableCell>{nutritionData.glucides}</TableCell>
            <TableCell>{nutritionData.lipides}</TableCell>
            <TableCell>{nutritionData.repartitionfat}</TableCell>
            <TableCell>
              {nutritionData.datenutrition
                ? new Date(nutritionData.datenutrition).toLocaleDateString()
                : "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                value={editableApportCalorique}
                onChange={handleApportCaloriqueChange}
              />
            </TableCell>
            <TableCell>{editableProteines}</TableCell>
            <TableCell>{editableGlucides}</TableCell>
            <TableCell>{editableLipides}</TableCell>
            <TableCell>
              <TextField
                value={editableRepartitionFat}
                onChange={handleRepartitionFatChange}
              />
            </TableCell>
            <TableCell>{new Date().toLocaleDateString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button onClick={handleSave}>Save</Button>
    </TableContainer>
  );
};

export default NutritionTable;
